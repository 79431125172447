import * as React from 'react';

import { Contact, HeroSectionCommercial, PropertyTaxServices } from '../components/landing';
import { Footer, GoogleNoIndex, PageTitle } from '../components/shared';

const LinkedinComLandingPage: React.FunctionComponent = () => {
  return (
    <>
      <PageTitle />
      <GoogleNoIndex />
      <HeroSectionCommercial />
      <PropertyTaxServices />
      <Contact />
      <Footer />
    </>
  );
};

export default LinkedinComLandingPage;
